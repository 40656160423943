/** @define order; weak */
.order {
  padding: 0 15px;
  color: #ffffff;
}
@media only screen and (max-width: 599px) {
  .order {
    background: url("../../../images/banner-order/order_bcg_400.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 600px) and (max-width: 699px) {
  .order {
    background: url("../../../images/banner-order/order_bcg_600.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 700px) {
  .order {
    padding: 0 15px 0 0;
  }
}
.no-webp {
  /* postcss-bem-linter: ignore */
}
@media only screen and (max-width: 599px) {
  .no-webp .order {
    background: url("../../../images/banner-order/order_bcg_400.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 600px) and (max-width: 699px) {
  .no-webp .order {
    background: url("../../../images/banner-order/order_bcg_600.jpg") no-repeat top right;
  }
}
.order__info {
  padding-top: 100px;
  text-align: right;
}
@media only screen and (min-width: 700px) {
  .order__info {
    width: 40%;
    padding: 160px 30px 0 30px;
  }
}
@media only screen and (min-width: 1200px) {
  .order__info .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 1400px) {
  .order__info .title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .order__info {
    background: url("../../../images/banner-order/order_bcg_400.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .order__info {
    padding: 230px 30px 0 30px;
    background: url("../../../images/banner-order/order_bcg_600.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 1500px) {
  .order__info {
    padding: 330px 80px 0 30px;
    background: url("../../../images/banner-order/order_bcg_850.webp") no-repeat top right;
  }
}
.no-webp {
  /* postcss-bem-linter: ignore */
}
@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .no-webp .order__info {
    background: url("../../../images/banner-order/order_bcg_400.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .no-webp .order__info {
    background: url("../../../images/banner-order/order_bcg_600.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 1500px) {
  .no-webp .order__info {
    background: url("../../../images/banner-order/order_bcg_850.jpg") no-repeat top right;
  }
}
.order__form {
  width: 100%;
  padding: 30px 0 80px 0;
}
@media only screen and (min-width: 700px) {
  .order__form {
    width: 60%;
    padding-left: 30px;
    background: url("../../../images/order_form_bcg.webp") no-repeat bottom left;
    border-left: 1px solid #2b3042;
  }
}
@media only screen and (min-width: 1500px) {
  .order__form {
    padding-right: 80px;
    padding-left: 80px;
  }
}
.no-webp {
  /* postcss-bem-linter: ignore */
}
.no-webp .order__form {
  background: url("../../../images/order_form_bcg.jpg") no-repeat bottom left;
}
/*# sourceMappingURL=css/order.css.map */